body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0; /* Light grey background */
    color: #333; /* Dark text color */
    margin: 0; /* Remove default body margin */
}

html {
    scroll-behavior: smooth;
}

footer a {
    text-decoration: none;
}

video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}

video::-webkit-media-controls {
    opacity: 0;
}

video {
    pointer-events: none;
}

.header-top {
    background: #e8e8e8; 
    padding: 10px 0;
}

.header-top p {
    margin: 0;
    font-size: 14px;
    color: #000; 
}

.header-top .list-inline {
    margin: 0;
}

.header-top .list-inline li {
    display: inline;
    margin-right: 10px;
}

.navbar {
    background: #fff; /* White background for navbar */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative; /* Relative positioning for scrolling behavior */
}

.navbar .navbar-brand img {
    height: 50px;
}

.navbar .nav-link {
    color: #333; /* Dark text color for navbar links */
    padding: 15px 20px;
    position: relative;
}

.navbar .nav-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #333; /* Underline color */
    transition: width 0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
}

.navbar .nav-link:hover::after,
.navbar .nav-link-home::after {
    width: 100%;
}

.navbar .btn-danger {
    background-color: #d9534f; /* Red button color */
    border: none;
}

.navbar .btn-danger:hover {
    background-color: #c9302c; /* Darker red on hover */
}

/* Carousel Section */
.carousel-inner {
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: auto;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire carousel item */
}

.carousel-caption {
    background: rgba(0, 0, 0, 0.5); /* Optional: Semi-transparent background */
    padding: 20px;
    border-radius: 10px;
}

.ml-auto {
    margin-left: auto;
}

.carousel-caption h1, .carousel-caption p, .carousel-caption .btn {
    margin-bottom: 15px;
}

.carousel-caption .btn {
    font-size: 18px;
    padding: 10px 20px;
    background-color: #EE2E24; /* Red button color */
    border: none;
}

.carousel-caption .btn:hover {
    background-color: #c9302c; /* Darker red on hover */
}

.featuresbg {
    background-color: rgb(255, 255, 255);
    padding: 50px 0;
}

.section-title {
    font-size: 36px;
    margin-bottom: 20px;
    color: #EE2E24; /* Header color */
    text-transform: uppercase;
    text-align: center;
}

.section-description {
    text-align: center;
    font-size: 18px;
    color: #555;
    margin-bottom: 50px; /* Adjust for space between description and features */
}

.features-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Add space between feature cards */
    justify-content: center; /* Center the cards */
}

.feature {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex: 1 1 calc(33.333% - 40px); /* Adjust width and account for gap */
    max-width: 500px; /* Set a max-width for better control */
}

.feature-icon {
    margin-bottom: 15px;
    color: #EE2E24; /* Icon color */
}

.feature h4 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

.feature p {
    font-size: 16px;
    color: #777;
}

.feature:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
    .feature {
        width: 100%;
        margin-bottom: 20px;
    }
}

.feature-icon {
    margin-bottom: 15px;
    color: #EE2E24; /* Icon color */
}

/* Remove gaps between features */
.features .feature:nth-child(odd) {
    border-right: 0;
}

.features .feature:nth-child(even) {
    border-left: 0;
}

.features .feature + .feature {
    border-top: 0;
}

/* Why Us Section */
.why-us {
    background: #fff;
    padding: 50px 0;
}

.section-title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
    color: #EE2E24; /* Red color for titles */
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
}

.section-description {
    text-align: center;
    margin-bottom: 50px;
    font-size: 18px;
    color: #555;
}

.expertise-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px; /* Add some space between cards */
    list-style: none;
    padding: 0;
    margin: 0;
}

.expertise-card {
    background: #fff;
    padding: 20px;
    text-align: center;
    border: 1px solid #ddd; /* Border for each card */
    border-radius: 8px; /* Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.expertise-card:hover {
    transform: translateY(-20px); /* Move the card up more on hover */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* Larger shadow on hover */
}

.expertise-card i {
    font-size: 36px;
    color: #EE2E24; /* Red color for icons */
    margin-bottom: 15px;
}

.expertise-card h4 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

.expertise-card p {
    font-size: 16px;
    color: #777;
}

/* Projects Section */
.projects {
    background: #f8f8f8;
    padding: 50px 0;
}

.projects h3 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 600;
    color: #EE2E24; /* Red color for titles */
}

.projects p {
    text-align: center;
    margin-bottom: 50px;
    font-size: 18px;
    color: #555;
}

.projects .project-carousel .item {
    text-align: center;
}

.project-caption {
    background: #fff;
    padding: 10px;
    margin-top: -5px;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.project-caption h6 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.col-md-2 h6 {
    font-size: large;
}

.col-md-5 h6 {
    font-size: large;
}

/* Equipment Highlight Section */
.equipment-highlight, .about-us {
    padding: 50px 0;
    flex: 1;
    margin: 15px 0; /* Adjust margin for better spacing */
}

.equipment-highlight h3, .about-us h3 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 30px;
    color: #EE2E24; /* Red color for titles */
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center; /* Center the headings */
}

#equipmentAccordion {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Add space between cards */
}

@media (max-width: 768px) {
    .equipment-container {
      flex-direction: column;
      gap: 10px; /* Adjust the gap for mobile view */
    }
  
    .equipment-card {
      flex: 1 1 100%; /* Full width for mobile view */
      max-width: 100%; /* Remove max-width for mobile view */
    }
  }

.equipment-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Add space between the cards */
    justify-content: center; /* Center the cards */
}
  
.equipment-icon {
    font-size: 36px;
    color: #EE2E24; /* Icon color */
    margin-bottom: 15px;
}

.equipment-card {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex: 1 1 calc(33.333% - 40px); /* Adjust width and account for gap */
    max-width: 500px; /* Set a max-width for better control */
    text-align: center;
}

.equipment-card h4 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

.equipment-card p {
    font-size: 16px;
    color: #777;
}

.equipment-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.equipment-card .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    padding: 0;
    color: #333;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.equipment-card .card-header:hover {
    color: #EE2E24;
}

.equipment-card .card-header i {
    margin-right: 10px;
    color: #EE2E24;
}

.equipment-card .card-body {
    padding: 20px;
    color: #555;
}

.equipment-card .card-body h5 {
    font-size: 24px;
    color: #EE2E24;
    margin-bottom: 10px;
}

.equipment-card .card-body p {
    font-size: 16px;
    line-height: 1.6;
}

#equipmentAccordion .card-header a {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px; /* Ensure padding is consistent */
    color: #333;
    background: transparent;
    border-bottom: 2px solid #999; /* Underline effect */
    transition: background 0.3s ease, color 0.3s ease, border-bottom 0.3s ease;
}

#equipmentAccordion .card-header a:hover {
    background-color: #EE2E24; /* Hover background color */
    color: white; /* Hover text color */
    border-bottom: 2px solid white; /* Hover underline color */
}

#equipmentAccordion .card-header a:not(.collapsed) {
    background-color: #EE2E24; /* Active background color */
    color: white; /* Active text color */
    border-bottom: 2px solid white; /* Active underline color */
}

.panel-title a {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px; /* Ensure padding is consistent */
    color: #333;
    background: transparent;
    border-bottom: 2px solid #999; /* Underline effect */
    transition: background 0.3s ease, color 0.3s ease, border-bottom 0.3s ease;
}

.panel-title a:hover {
    background-color: #EE2E24; /* Hover background color */
    color: white; /* Hover text color */
    border-bottom: 2px solid white; /* Hover underline color */
}

.panel-title a:after {
    content: '\f067'; /* FontAwesome plus icon */
    font-family: FontAwesome;
    font-size: 16px;
    transition: transform 0.3s ease;
}

.panel-title a.collapsed:after {
    transform: rotate(0deg);
    content: '\f067'; /* FontAwesome plus icon */
}

.panel-title a:not(.collapsed) {
    background-color: #EE2E24; /* Active background color */
    color: white; /* Active text color */
    border-bottom: 2px solid white; /* Active underline color */
}

.panel-title a:not(.collapsed):after {
    content: '\f068'; /* FontAwesome minus icon */
    transform: rotate(180deg);
}

.panel-body {
    padding: 20px;
    border: none;
    background: #f8f8f8;
}

.panel-body img {
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.panel-body h5 {
    margin-top: 0;
    color: #EE2E24; /* Red color for titles */
}

/* View More Button */
.view-more {
    text-align: center; /* Align the button to the right */
    padding: 0px 20px 30px;
    margin-top: 20px; /* Add space above the button */
}

.btn-view-more {
    background-color: #EE2E24; /* Button background color */
    color: white; /* Button text color */
    padding: 10px 20px; /* Button padding */
    text-decoration: none; /* Remove underline from text */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth background transition */
}

.btn-view-more:hover {
    color: white;
    background-color:
    #c9302c; /* Darker red on hover */
}

/* About Us Section */
.about-us-section {
    background: #fff;
    padding: 50px 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.about-us-section h3 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 30px;
    color: #EE2E24;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.about-us-section p {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 30px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.about-us-details {
    text-align: left;
    margin: 0 auto;
    max-width: 800px;
}

.about-us-details ul {
    list-style-type: none;
    padding: 0;
}

.about-us-details ul li {
    background: #f9f9f9;
    margin: 10px 0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-us-details ul li:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.about-us-details ul li h5 {
    margin: 0 0 0 15px; /* Add space between icon and text */
    font-size: 22px;
    color: #333;
}

.about-us-details ul li p {
    margin: 10px 0 0 37px; /* Add space to align with h5 text */
    font-size: 16px;
    color: #777;
}

.about-us-details ul li::before {
    content: '\f058';
    font-family: 'Font Awesome 5 Free'; 
    font-weight: 900;
    font-size: 24px;
    color: #EE2E24;
}

/* Request Consultation Section */
.request-consultation {
    background: #f8f8f8; /* Light grey background to match the rest of the site */
    padding: 50px 0;
}

.request-consultation h3 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
    color: #EE2E24; /* Red color for titles */
    font-weight: 600;
}

.request-consultation form {
    background: #fff;
    padding: 30px;
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modern-input {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    width: 100%; /* Ensure full width */
    margin-bottom: 15px; /* Space between inputs */
}

.modern-input:focus {
    border-color: #EE2E24; /* Red border on focus */
    box-shadow: 0 0 5px rgba(217, 83, 79, 0.5); /* Subtle shadow on focus */
    outline: none;
}

.request-consultation .btn-danger.custom-btn {
    background-color: #d9534f;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    color: #fff;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.request-consultation .btn-danger.custom-btn:hover {
    background-color: #c9302c;
}

/* Footer Section */
.footer-cta {
    background: #333;
    padding: 20px 0;
    color: #fff;
}

.footer-cta .btn {
    background: #EE2E24; /* Red button color */
    color: #fff;
    border: none;
}

.footer-cta .btn:hover {
    background: #c9302c; /* Darker red on hover */
}

.footer-main {
    background: #222;
    padding: 50px 0;
    color: #fff;
}

.footer-main .footer-logo {
    margin-bottom: 20px;
    max-width: 100%;
}

.footer-main h6 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #EE2E24; /* Title color */
    text-transform: uppercase;
    letter-spacing: 1px;
}

.footer-main .footer-links,
.footer-main .footer-contact {
    list-style: none;
    padding: 0;
}

.footer-main .footer-links li,
.footer-main .footer-contact li {
    margin-bottom: 10px;
}

.footer-main .footer-links a,
.footer-main .footer-contact a {
    color: #fff;
    text-decoration: none;
    position: relative;
    display: inline-block;
    transition: color 0.3s ease;
}

.footer-main .footer-links a::after,
.footer-main .footer-contact a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: red;
    transition: width 0.4s ease;
}

.footer-main .footer-links a:hover::after,
.footer-main .footer-contact a:hover::after {
    width: 100%;
    left: 0;
    background: red;
}

.footer-main .footer-links a:hover,
.footer-main .footer-contact a:hover {
    color: red;
}

.footer-bottom {
    background: #111;
    padding: 10px 0;
    text-align: center;
    color: #777;
}

.footer-bottom a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-bottom a:hover {
    color: red;
}

.footer-bottom a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: red;
    transition: width 0.4s ease;
}

.footer-bottom a:hover::after {
    width: 100%;
    left: 0;
}

.footer {
    background-color: #f8f9fa;
    padding: 20px 0;
}

.footer-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.footer-links a, .footer-contact a, .footer-bottom a {
    text-decoration: none;
    position: relative;
    display: inline-block;
}

.footer-links a::after, .footer-contact a::after, .footer-bottom a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: red;
    transition: width 0.4s ease;
}

.footer-links a:hover::after, .footer-contact a:hover::after, .footer-bottom a:hover::after {
    width: 100%;
    left: 0;
    background: red;
}
.footer-contact a:hover::after {
    width: 100%;
    left: 0;
    background: red;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.equipment-list {
    margin-top: 20px;
}

.manufacturer-group {
    margin-bottom: 30px;
}

.manufacturer-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.equipment-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.equipment-card {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex: 1 1 calc(33.333% - 40px); /* Adjust width and account for gap */
    max-width: 400px; /* Optional: Set a max-width for better control */
    text-align: center;
}

.equipment-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.equipment-card .card-title {
    font-size: 20px;
    font-weight: bold;
}

.equipment-card .card-subtitle {
    font-size: 16px;
    color: #777;
}

.equipment-card .card-text {
    font-size: 14px;
    color: #555;
}

.equipment-card .equipment-icon {
    font-size: 36px;
    color: #EE2E24;
   
    margin-bottom: 15px;
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
    .equipment-card {
        flex: 1 1 100%; /* Full width for mobile view */
        max-width: 100%; /* Remove max-width for mobile view */
    }

    .equipment-highlight, .about-us {
        flex: 1 1 100%;
        max-width: 100%; /* Ensure full width */
        margin: 0; /* Remove margin for full-width on mobile */
        padding: 20px; /* Adjust padding for better spacing */
    }

    .view-more {
        text-align: center; /* Center the button on mobile */
        margin-top: 20px; /* Add space above the button */
    }

    .btn-view-more {
        width: 100%; /* Full-width button on mobile */
        margin-top: 10px; /* Add space above the button */
    }
}

/* Footer Enhancements */
.footer {
    background: #222;
    color: #fff;
    padding: 50px 0;
    text-align: center;
}

.footer a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer a:hover {
    color: #EE2E24;
}

.footer .footer-title {
    font-size: 18px;
    margin-bottom: 10px;
    color: #EE2E24;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.footer .footer-links,
.footer .footer-contact {
    list-style: none;
    padding: 0;
}

.footer .footer-links li,
.footer .footer-contact li {
    margin-bottom: 10px;
}

.footer .footer-links a::after,
.footer .footer-contact a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #EE2E24;
    transition: width 0.4s ease;
}

.footer .footer-links a:hover::after,
.footer .footer-contact a:hover::after {
    width: 100%;
    left: 0;
}

.footer-bottom {
    background: #111;
    padding: 10px 0;
    text-align: center;
    color: #777;
}

.footer-bottom a:hover::after {
    width: 100%;
    left: 0;
    background: red;
}

.footer-bottom a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-bottom a:hover {
    color: #EE2E24;
}

.footer-bottom a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #EE2E24;
    transition: width 0.4s ease;
}

.footer-bottom a:hover::after {
    width: 100%;
    left: 0;
}
