body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0; /* Light grey background */
    color: #333; /* Dark text color */
    margin: 0; /* Remove default body margin */
}

.header-top {
    background: #e8e8e8; 
    padding: 10px 0;
}

.header-top p {
    margin: 0;
    font-size: 14px;
    color: #000; 
}

.navbar {
    background: #fff; /* White background for navbar */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative; /* Relative positioning for scrolling behavior */
}

.navbar .navbar-brand img {
    height: 50px;
}

.navbar .nav-link {
    color: #333; /* Dark text color for navbar links */
    padding: 15px 20px;
    position: relative;
}

.navbar .nav-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #333; /* Underline color */
    transition: width 0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
}

.navbar .nav-link:hover::after,
.navbar .nav-link-home::after {
    width: 100%;
}

.navbar .btn-danger {
    background-color: #d9534f; /* Red button color */
    border: none;
}

.navbar .btn-danger:hover {
    background-color: #c9302c; /* Darker red on hover */
}


/* Carousel Section */
.carousel-inner {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire carousel item */
}

.carousel-caption {
    background: rgba(0, 0, 0, 0.5); /* Optional: Semi-transparent background */
    padding: 20px;
    border-radius: 10px;
}

.carousel-caption h1, .carousel-caption p, .carousel-caption .btn {
    margin-bottom: 15px;
    font-weight: 700px;
}

.carousel-caption .btn {
    font-size: 18px;
    padding: 10px 20px;
    background-color: #EE2E24; /* Red button color */
    border: none;
}

.carousel-caption .btn:hover {
    background-color: #c9302c; /* Darker red on hover */
}

/* Equipment List Section */
.equipment-list {
    background-color: #f0f0f0; /* Light grey background */
    padding: 50px 0;
}

.equipment-list .section-title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
    color: #EE2E24; /* Red color for titles */
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
}

.equipment-list .section-description {
    text-align: center;
    margin-bottom: 50px;
    font-size: 18px;
    color: #555;
}

.manufacturer-group {
    margin-bottom: 40px;
}

.manufacturer-title {
    font-size: 24px;
    color: #EE2E24; /* Red color for manufacturer titles */
    text-transform: uppercase;
    margin-bottom: 20px;
}

.equipment-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.equipment-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.equipment-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.equipment-card .card-body {
    padding: 20px;
}

.equipment-card h5 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
}

.equipment-card h6 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #777;
}

.equipment-card p {
    font-size: 14px;
    color: #777;
    line-height: 1.6;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .equipment-card .card-body {
        padding: 15px;
    }

    .equipment-card h5, .equipment-card h6, .equipment-card p {
        text-align: center;
    }
}

/* Footer Section */
.footer-cta {
    background: #333;
    padding: 20px 0;
    color: #fff;
}

.footer-cta .btn {
    background: #EE2E24; /* Red button color */
    color: #fff;
    border: none;
}

.footer-cta .btn:hover {
    background: #c9302c; /* Darker red on hover */
}

.footer-main {
    background: #222;
    padding: 50px 0;
    color: #fff;
}

.footer-main .footer-logo {
    margin-bottom: 20px;
}

.footer-main .footer-links {
    list-style: none;
    padding: 0;
}

.footer-main .footer-links li {
    margin-bottom: 10px;
}

.footer-main .footer-contact {
    list-style: none;
    padding: 0;
}

.footer-main .footer-contact li {
    margin-bottom: 10px;
}

.footer-bottom {
    background: #111;
    padding: 10px 0;
    text-align: center;
    color: #777;
}


.footer-certifications {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.footer-certifications img {
    width: 100px;
    height: auto;
}

.footer-logo {
    width: 100px;
    height: auto;
}